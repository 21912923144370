
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    /*
| Developed by Dirupt
| Filename : login.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/
import React from 'react';
import * as Yup from 'yup';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { AxiosError } from 'axios';
import { Form, Formik } from 'formik';
import Trans from 'next-translate/Trans';
import { toast } from 'sonner';
import { SensklePageFactory } from '@/components/ssr/SensklePage';
import { createAuthServerSideProps } from '@/components/ssr/createServerSideProps';
import { Link } from '@/components/common/MLink';
import { MButton } from '@/components/common/MButton';
import { UrlsConfig } from '@/config/urls.config';
import { IN_DEV } from '@/config/common.config';
import { MDarkModeSwitch } from '@/components/common/MDarkModeSwitch';
import { AuthLoginDto } from '@/services/api/modules/auth/auth.dto';
import { AuthEndpoint } from '@/services/api/modules/auth/auth.endpoint';
import { EmailField, PasswordField } from '@/components/formik';
import { AxiosErrorHandler } from '@/services/api/axios-error-handler';
import { LangSwitch } from '@/components/common/LangSwitch';
/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/
const initialValues: AuthLoginDto = {
    email: IN_DEV ? 'philippe@dirupt.com' : '',
    password: IN_DEV ? 'DGsrgqr5@B@JETQ&DRgWoSW3' : '',
    rememberMe: false,
};
/*
|--------------------------------------------------------------------------
| Page
|--------------------------------------------------------------------------
*/
const LoginPage = SensklePageFactory(() => {
    const { t } = useTranslation();
    const router = useRouter();
    const [apiError, setApiError] = React.useState('');
    const validationSchema = React.useMemo(() => {
        return Yup.object().shape({
            email: Yup.string().email().required(),
            password: Yup.string().required(),
            rememberMe: Yup.boolean(),
        });
    }, []);
    // Methods
    //--------------------------------------------------------------------------
    const handleLogin = async (values: AuthLoginDto) => {
        try {
            await AuthEndpoint.login(values);
            await router.push(UrlsConfig.feed);
        }
        catch (error) {
            if (error instanceof AxiosError) {
                switch (error?.response?.status) {
                    case 400:
                    case 401:
                        setApiError('auth/login:errors.invalidCredentials');
                        break;
                    case 403:
                        if (error.response.data?.code === 'E_BETA_ACCESS')
                            await router.push(UrlsConfig.auth.betaRestricted);
                        else
                            await router.push(UrlsConfig.auth.verify());
                        break;
                    default:
                        toast.error(t('common:errors.errorOccurred'));
                }
            }
            else {
                AxiosErrorHandler.handle(t, error);
            }
            console.log(error); // TODO handle error
        }
    };
    // Render
    // ----------------------------------------------------------------------------
    return (<Formik initialValues={initialValues} onSubmit={handleLogin} validationSchema={validationSchema}>
			{(formikProps) => (<Form>
					<Box display={'flex'} flexDirection={'column'} justifyContent={'center'} gap={4} width={'100%'}>
						<Box textAlign={'center'}>
							<Typography variant={'h1'}>{t('auth/login:title')}</Typography>
							<Typography>{t('auth/login:subTitle')}</Typography>
						</Box>
						<Box display={'flex'} flexDirection={'column'} gap={2}>
							<EmailField />
							<Box>
								<PasswordField />
								<Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={2}>
									<FormControlLabel control={<Checkbox onChange={(event) => formikProps.setFieldValue('rememberMe', event.target.checked)} value={formikProps.values.rememberMe}/>} label={t('auth/login:rememberMe')}/>
									<Link href={UrlsConfig.auth.forgotPassword}>{t('auth/login:forgotPassword')}</Link>
								</Box>
							</Box>
						</Box>
						<Box display={'flex'} flexDirection={'column'} gap={1}>
							{apiError ? (<Box display="flex" gap={0.5} justifyContent={'center'} color={'error.main'}>
									<Trans i18nKey={apiError} components={[<Link key={0} href={UrlsConfig.auth.createAccount}/>]}/>
								</Box>) : null}
							<MButton variant="contained" type="submit" size={'large'} disabled={formikProps.isSubmitting}>
								{t('auth/login:loginButton')}
							</MButton>
						</Box>
						<Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} gap={0.5}>
							<Typography variant={'body2'}>{t('auth/login:noAccountRegister')}</Typography>
							<MButton variant={'text'} component={Link} href={UrlsConfig.auth.createAccount}>
								{t('auth/login:createAnAccount')}
							</MButton>
						</Box>
						<Box display={'flex'} justifyContent={'space-between'} gap={2}>
							<MDarkModeSwitch />
							<LangSwitch />
						</Box>
					</Box>
				</Form>)}
		</Formik>);
});
/*
|--------------------------------------------------------------------------
| Configurations
|--------------------------------------------------------------------------
*/
LoginPage.layout = 'auth';
export default LoginPage;
/*
|--------------------------------------------------------------------------
| Server Side Props
|--------------------------------------------------------------------------
*/
const getServerSideProps = createAuthServerSideProps();

    async function __Next_Translate__getServerSideProps__19509752b4d__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/auth/login',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__19509752b4d__ as getServerSideProps }
  